import { reactive, readonly } from "vue";
import { reportPointHit } from "@/helpers/analytics"
import { waitAMoment } from "@/helpers/wait"
import loadFolders from "@/store/folders"
import loadGroups from "@/store/groups"
import loadPoints from "@/store/points"
import loadSubmaps from "@/store/submaps"

const onPointsLoaded: Function[] = []

const onPwaConfigLoaded: Function[] = []

const state = reactive({
   accountCodeName: '',
   enabled: true,
   folders: [] as any[],
   googleMap: undefined as any,
   googleMapElement: undefined as any,
   groupsByPointGuid: {} as any,
   pointsByGuid: {} as any,
   pwaConfig: {} as any,
   selectedFolder: undefined as any,
   selectedPoint: undefined as any,
   selectedSubmap: undefined as any,
   submapsByPointGuid: {} as any,
   submapsBySubmapGuid: {} as any,
})

const getGoogleMap = () => state.googleMap

const setGoogleMap = (map: google.maps.Map, mapElement: HTMLElement) => {
   state.googleMap = map
   state.googleMapElement = mapElement
}

let isPwaConfigLoaded = false

const runPwaConfigLoadedCallbacks = () => onPwaConfigLoaded.forEach(callback => callback())

const loadPwaConfig = async (): Promise<any> => {
   const url = `https://pointsmap.com/${state.accountCodeName}/data/pwaConfig.json`
   const response = await fetch(url)
   if (response.status === 404) {
      alert(
        `Either the "${state.accountCodeName}" PointsMap has not been updated recently `
        + `or there is no PointsMap by that name.`
      )
      throw new Error(`No PWA Config found at ${url}`)
   }
   state.pwaConfig = await response.json()
   isPwaConfigLoaded = true
   waitAMoment().then(runPwaConfigLoadedCallbacks)
   return state.pwaConfig
}

const getPwaConfig = async (): Promise<any> => {
   if (isPwaConfigLoaded) {
      return state.pwaConfig
   }
   return new Promise(resolve => {
      onPwaConfigLoaded.push(() => resolve(state.pwaConfig))
   })
}

const runPointsLoadedCallbacks = () => onPointsLoaded.forEach(callback => callback())

const addWebAppManifest = (accountCodeName: string, pwaConfig: any) => {
   const manifestLink = document.getElementById('manifest-placeholder')
   if (manifestLink) {
      const manifestData = {
         "name": pwaConfig.app_name,
         "short_name": pwaConfig.config_label || pwaConfig.app_name,
         "theme_color": `#${pwaConfig.color}`,
         "background_color": `#${pwaConfig.config_color || pwaConfig.color}`,
         "display_override": ["minimal-ui"],
         "display": "standalone",
         "orientation": "portrait",
         "scope": `${location.origin}/${accountCodeName}`,
         "start_url": `${location.origin}/${accountCodeName}`,
         "icons": [
            {
               "src": `https://pointsmap.com/images/uploads/${pwaConfig.pwa_homescreen_icon}`,
               "type": "image/png",
               "sizes": "192x192",
               "purpose": "any"
            },
            {
               "src": `https://pointsmap.com/images/uploads/${pwaConfig.pwa_homescreen_icon}`,
               "type": "image/png",
               "sizes": "192x192",
               "purpose": "maskable"
            }
         ]
      }
      const manifestJson = JSON.stringify(manifestData);
      const manifestJsonBlob = new Blob([manifestJson], {type: 'application/json'});
      const manifestURL = URL.createObjectURL(manifestJsonBlob);
      manifestLink.setAttribute('href', manifestURL);
   }
}

const addAppleTouchIconLink = (appleTouchIconFileName: string|null) => {
   if (appleTouchIconFileName) {
      const appleTouchIconLink = document.getElementById('apple-touch-icon-placeholder') as HTMLLinkElement;
      if (appleTouchIconLink) {
         appleTouchIconLink.href = `https://pointsmap.com/images/uploads/${appleTouchIconFileName}`;
      }
   }
}

const indexSubmapsBySubmapGuid = (submapsByPointGuid: any) => {
   for (const pointGuid in submapsByPointGuid) {
      const submapsForPoint = submapsByPointGuid[pointGuid]
      submapsForPoint.forEach((submap: any) => {
         state.submapsBySubmapGuid[submap.guid] = submap
      })
   }
}

const loadAppData = async (accountCodeName: string) => {
   isPwaConfigLoaded = false
   state.accountCodeName = accountCodeName
   return loadPwaConfig().then(async pwaConfig => {
      document.body.style.setProperty('--ion-color-primary', `#${pwaConfig.color}`)
      state.enabled = !!Number(pwaConfig.enabled)
      if (state.enabled) {
         state.folders = await loadFolders(state.accountCodeName)
         state.pointsByGuid = await loadPoints(state.accountCodeName)
         state.groupsByPointGuid = await loadGroups(state.accountCodeName)
         state.submapsByPointGuid = await loadSubmaps(state.accountCodeName)
         indexSubmapsBySubmapGuid(state.submapsByPointGuid)
         waitAMoment().then(runPointsLoadedCallbacks)
         addWebAppManifest(accountCodeName, pwaConfig)
         addAppleTouchIconLink(pwaConfig.pwa_homescreen_icon)
      }
   })
}

const recheckEnabledState = async () => {
   return loadPwaConfig().then(async pwaConfig => {
      state.enabled = !!Number(pwaConfig.enabled)
   })
}

const onDocumentFocus = () => {
   
   if (document.visibilityState === 'visible') {
      recheckEnabledState()
   }
}

const onVisibilityChange = () => {
   if (document.visibilityState === 'visible') {
      recheckEnabledState()
   }
}

const onWindowFocus = () => {
   if (document.visibilityState === 'visible') {
      recheckEnabledState()
   }
}

const selectFolder = (folderGuid: any) => {
   state.selectedFolder = folderGuid
}

const selectPoint = (pointGuid: any) => {
   state.selectedPoint = pointGuid
   if (pointGuid) {
      waitAMoment().then(() => {
         return reportPointHit(
           state.accountCodeName,
           pointGuid,
           state.selectedSubmap
         )
      })
   }
}

const selectSubmap = (submapGuid: any) => {
   state.selectedSubmap = submapGuid
}

const isSelectedFolder = (folderGuid: any) => {
   return state.selectedFolder === folderGuid
}

const isSelectedPoint = (pointGuid: any) => {
   return state.selectedPoint === pointGuid
}

const getGroup = (pointGuid: any) => state.groupsByPointGuid[pointGuid]

const getSubmap = (submapGuid: any) => state.submapsBySubmapGuid[submapGuid]

const getSubmapsForPoint = (pointGuid: any) => state.submapsByPointGuid[pointGuid] || []

const getPoint = (pointGuid: any) => state.pointsByGuid[pointGuid]

const haveLoadedPoints = () => Object.keys(state.pointsByGuid).length > 0

const getPointOnceLoaded = async (pointGuid: any) => {
   if (haveLoadedPoints()) {
      return getPoint(pointGuid)
   }
   return new Promise(resolve => {
      onPointsLoaded.push(() => resolve(getPoint(pointGuid)))
   })
}

const getPointsByGuidOnceLoaded = async () => {
   if (haveLoadedPoints()) {
      return state.pointsByGuid
   }
   return new Promise(resolve => {
      onPointsLoaded.push(() => resolve(state.pointsByGuid))
   })
}

const getFolderFrom = (folderGuid: string|undefined, folders: Folder[]): Folder|undefined => {
   for (const folder of folders) {
      if (folder.guid === folderGuid) {
         return folder
      }
      const match = getFolderFrom(folderGuid, folder.folders)
      if (match !== undefined) {
         return match
      }
   }
   return undefined
}

const getFolder = (folderGuid: string|undefined): Folder|undefined => {
   return getFolderFrom(folderGuid, state.folders)
}

const getAllFoldersContainingFolder = (folder: any): Array<any> => {
   const results = []
   if (folder.parentFolderId) {
      const parentFolder = getFolder(folder.parentFolderId)
      if (parentFolder) {
         results.push(parentFolder)
         const parentFolderAncestors = getAllFoldersContainingFolder(parentFolder)
         results.push(...parentFolderAncestors)
      }
   }
   return results
}

const getFoldersContainingPoint = (point: any) => {
   const results = []
   const folderGuids = [point.folder, ...point.secondaryFolders]
   for (const folderGuid of folderGuids) {
      const folder = getFolder(folderGuid)
      if (folder) {
         results.push(folder)
         const folderAncestors = getAllFoldersContainingFolder(folder)
         results.push(...folderAncestors)
      }
   }
   return results
}

const isAFolderSelected = (): boolean => {
   return !!state.selectedFolder
}

const isInitiallyVisible = (folderGuid: string): boolean => {
   const folder = getFolder(folderGuid)
   if (folder) {
      return !folder.initHid
   }
   return false
}

const isOrContainsSelectedFolder = (folder: any, selectedFolderGuid: string|undefined) => {
   if (!selectedFolderGuid) {
      return false
   }
   if (folder.guid === selectedFolderGuid) {
      return true
   }
   return folder.folders.some((subfolder: any) => isOrContainsSelectedFolder(subfolder, selectedFolderGuid))
}

const isPointInFolder = (pointGuid: string, folder: any): boolean => {
   if (!pointGuid || !folder) {
      return false
   }
   if (folder.pointGuids.some((guid: any) => guid === pointGuid)) {
      return true
   }
   return folder.folders.some((subfolder: any) => isPointInFolder(pointGuid, subfolder))
}

export default {
   getFolder,
   getFoldersContainingPoint,
   getGoogleMap,
   getGroup,
   getPoint,
   getPointOnceLoaded,
   getPointsByGuidOnceLoaded,
   getPwaConfig,
   getSubmap,
   getSubmapsForPoint,
   isAFolderSelected,
   isInitiallyVisible,
   isOrContainsSelectedFolder,
   isPointInFolder,
   isSelectedFolder,
   isSelectedPoint,
   loadAppData,
   onDocumentFocus,
   onVisibilityChange,
   onWindowFocus,
   selectFolder,
   selectPoint,
   selectSubmap,
   setGoogleMap,
   state: readonly(state),
}
